<template>
  <div>
    <v-container class="container--fluid">
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-col cols="12" class="pl-0 pr-0 pb-1">
              <v-row>
                <v-spacer />

                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-select
                    v-model="santralModel"
                    :items="santralDataList"
                    label="Santral"
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <span
                        v-if="index === 0"
                        style="max-width: 120px"
                        class="d-inline-block text-truncate"
                        >{{ item }}</span
                      >
                      <span v-if="index === 1" class="grey--text text-caption"
                        >(+{{ santralModel.length - 1 }})</span
                      >
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-select
                    v-model="measureSelect"
                    :items="measures"
                    label=""
                  />
                </v-col>
                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker v-model="startDate" no-title scrollable>
                      <v-spacer />
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="3" sm="1" md="1" class="pr-3">
                  <v-btn color="primary" @click="getData"> Filtrele </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col :key="componentKey" cols="12" class="mt-10 mb-10">
              <echart
                id="santralheatmapsHourlyComparison"
                class-name="widget-content mt-10 mb-10"
                :height="height"
                width="100%"
                :chart-data="HeatmapData"
              />
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import * as Heatmap from "@/api/Heatmap/Heatmap";
import moment from "moment";
import i18n from "@/locale/index";
import Echart from "@/views/components/Echart/Chart.vue";
import { getSantralMinute } from "@/api/Request/santral";

export default {
  name: "Dashboard",
  components: {
    Echart,
  },
  data: () => ({
    menu: false,
    modal: false,
    menu2: false,
    mina: "",
    maxa: "",
    dialog: false,
    nowDate: new Date().toISOString().slice(0, 10),

    menu1: false,
    minDate: "",
    daterangetext: i18n.t("Tarih Seç"),
    selecteditemstext: "",
    mindate: "2010",
    all: i18n.t("All"),
    dateRules: [(v) => !!v || "Date is required"],
    dates: [],
    inverterList: {},
    measureList: [],
    santral_items: [],
    startDate: moment().format("YYYY-MM-DD"),
    finishDate: moment().format("YYYY-MM-DD"),
    minutesDailyEnergydate: new Date().toISOString().substr(0, 10),
    measures: [],
    measureSelect: "IstantaneousPower",
    componentKey: 1,
    title: "",
    height: "100px",
    santralModel: [],
    HeatmapData: {},
  }),
  computed: {
    santralDataList() {
      const { santralDataList } = this.$store.getters;
      if (santralDataList === undefined) return;

      this.santralModel = santralDataList;
      return santralDataList;
    },

    dateRangeText() {
      if (this.dates.length > 1) {
        const dateArr = this.dates;
        if (dateArr[0] > dateArr[1]) {
          this.selecteditemstext = `${dateArr[1]} ~ ${dateArr[0]}`;
        } else {
          this.selecteditemstext = `${dateArr[0]} ~ ${dateArr[1]}`;
        }
      } else {
        this.selecteditemstext = this.dates[0];
      }

      return this.selecteditemstext;
    },
    computedDateFormatted() {
      return this.formatDate();
    },
    maxDate() {
      return new Date().toISOString().substr(0, 10);
    },
  },
  watch: {
    santralModel() {
      if (Object.keys(this.santralModel) > 0) {
        this.getData();
      }
    },
  },
  created() {},
  mounted() {
    const myVar1 = setInterval(myTimer1, 1000);
    const self = this;
    let k = 0;
    function myTimer1() {
      // eslint-disable-next-line no-plusplus
      k++;

      if (k > 30) {
        clearInterval(myVar1);
      }
      const { statsmeasureList } = self.$store.getters;
      if (
        statsmeasureList !== undefined &&
        Object.keys(statsmeasureList).length > 0
      ) {
        clearInterval(myVar1);
        const measuresList = [];
        Object.keys(statsmeasureList).forEach((k) => {
          const obj = statsmeasureList[k];
          obj.text = i18n.t(obj.value);
          measuresList.push(obj);
        });
        self.measures = measuresList;
        setTimeout(() => {
          self.getData();
        }, 2000);
      }
    }
  },

  methods: {
    getData() {
      const formdata = {
        condiniton: {
          santral: this.santralModel,
          startDate: this.startDate,
          finishDate: this.finishDate,
          measures: this.measureSelect,
        },
      };

      let count = this.santralModel.length;
      if (count < 1) {
        count = 1;
      }
      this.height = `${count * 50}px`;
      // eslint-disable-next-line no-plusplus
      this.componentKey++;
      const step = this.$store.getters.stepTime;
      const tempdata = getSantralMinute(formdata);

      tempdata.then((_rawdata) => {
        let _s_measure;
        this.measures.forEach((v) => {
          if (this.measureSelect == v.value) {
            _s_measure = v;
          }
        });
        const temp = Heatmap.default.heatmapHour(
          _rawdata.data,
          _s_measure,
          this.santralModel,
          step
        );
        temp.then((res) => {
          this.HeatmapData = res;
          this.forceRerender();
        });
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },

    onDateSelected(daterange) {
      this.finishDate = moment(daterange[1]._d).format("YYYY-MM-DD");
      this.startDate = moment(daterange[0]._d).format("YYYY-MM-DD");
      const alrt = this.select_filter();

      if (alrt != undefined) {
        alert(alrt);
      }
    },
    cancelF() {
      this.menu = false;
      this.daterangetext = i18n.t("Date Range");
      this.$refs.menu.save(this.dates);
    },
    save(date) {
      this.daterangetext = `${i18n.t("Date Range")} ${i18n.t(
        this.selectcolumn
      )}`;
      this.$refs.menu.save(date);
      this.loading = true;
      const selectdate = this.dates;

      if (date.length < 2) {
        this.finishDate = selectdate[0];
        this.startDate = selectdate[0];
      } else if (selectdate[0] > selectdate[1]) {
        this.finishDate = selectdate[0];
        this.startDate = selectdate[1];
      } else {
        this.finishDate = selectdate[1];
        this.startDate = selectdate[0];
      }
    },
  },
};
</script>
<style scoped>
#heatmapsHourlypage {
  position: relative;
  height: 350px;
  margin: 1px;
  padding: 1px;
}

#textrotate {
  text-align: center;
}

#heatmapsHourly {
  height: 95%;
}
</style>
